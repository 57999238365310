import PropTypes from "prop-types";
import Icon from "./index";

const Click = (props) => Icon({ ...props, iconName: "click" });

Click.defaultProps = {
  isAriaHidden: false,
  alt: "get-started",
};

Click.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default Click;
