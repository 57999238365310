import PropTypes from "prop-types";
import Icon from "./index";

const BreadcrumpsIcon = (props) => Icon({ ...props, iconName: "breadcrumbs_arrow" });

BreadcrumpsIcon.defaultProps = {
  isAriaHidden: true,
  alt: "",
  // alt: "breadcrumbs arrow",
};

BreadcrumpsIcon.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default BreadcrumpsIcon;
