import "./style.scss";

import { useState } from "react";
import {
  errorFooterTextNotConnectServer,
  isNotFindEmailText,
  successFooterText,
} from "../../Notice/constantMessage";

import Email from "../../FormControl/InputField/_email";
import Notice from "../../Notice";
import PrivacyCheckbox from "../../FormControl/CheckboxNew/_privacy";
import PropTypes from "prop-types";
import SubmitButton from "../../Buttons/Submit";
import SuccessfullySubmit from "../../Notice/_successfullySubmit";
import { createClassName } from "../../../helpers";
import { defaultDataMapping } from "../../Api_request/dataMapping";
import { sendRequest } from "../../Api_request";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import TranslationWord from "../../Translate_component";

const cn = createClassName("email-form");

const ErrorConnectServerMessage = props => {
  return (
    <Notice
      errorFooterText={props.errorFooterTextNotConnectServer}
      className={props.fieldClassName}
      handler={props.handler}
      isLoading={props.isLoading}
    />
  );
};

const EmailForm = props => {
  const { t } = useTranslation("forms");
  const [isSend, getSending] = useState(false);
  const [isServerResponse, setServerResponse] = useState(true);
  const [isFindEmail, searchEmail] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      AcceptGuides: false,
    },
  });

  const onSubmit = data => {
    setLoading(true);
    sendRequest(
      `${props.apiLink}`,
      defaultDataMapping(data),
      getSending,
      setServerResponse,
      setLoading,
      searchEmail
    );
  };

  const successMessage = (
    <SuccessfullySubmit
      successfullySubmitTitle={t("common:done")}
      successHeaderTitle={props.successHeaderTitle}
      successFooterText={props.successFooterText}
      mailerAddress={props.withEmailAddress && watch("email")}
      nextStep={false}
      className={props.fieldClassName}
      handler={() => { }}
    />
  );

  const errorFindEmailMessage = (
    <Notice
      withButton={false}
      errorFooterText={props.isNotFindEmailText}
      mailerAddress={props.withEmailAddress && watch("email")}
      errorHeaderTitle={props.errorHeaderTitle}
      className={`${cn("message", { error: "find-email" })}  ${props.fieldClassName
        }`}
    />
  );

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)} className={props.classNameForm} noValidate>
        {!isSend ? (
          <>
            <Email
              isLabel
              labelPlacement="top"
              className={`${cn("email", { wrap: true })} ${props.fieldClassName
                }`}
              labelClassName={cn("email", { label: true })}
              errors={errors}
              register={register}
              isErrorState={errors?.email && true}
              name="email"
            />
            {props.withPrivacy && (
              <PrivacyCheckbox
                name="AcceptGuides"
                className={cn("checkbox")}
                tooltipContent={props.tooltipContent}
                register={register({ required: true })}
                isErrorState={errors?.AcceptGuides && true}
              />
            )}
            {!isServerResponse ? (
              <ErrorConnectServerMessage
                handler={handleSubmit(onSubmit)}
                isLoading={isLoading}
                {...props}
              />
            ) : (
                <>
                  {!isFindEmail && errorFindEmailMessage}
                  {<SubmitButton                                    
                    buttonTextKey={<TranslationWord text={(props.tryAgain && !isFindEmail) ? "try-again" : props.buttonTextKey} />}
                    className={`${props.classNameSubmit} ${props.spinerWrapClassName}`}
                    isLoading={isLoading}
                  />}
                </>
              )}
          </>
        ) : (
            successMessage
          )}
      </form>
      {props.content && props.content}
    </section>
  );
};

EmailForm.defaultProps = {
  errorFooterTextNotConnectServer: errorFooterTextNotConnectServer,
  successFooterText: successFooterText,
  isNotFindEmailText: isNotFindEmailText,
  fieldClassName: "",
  classNameForm: "",
  withPrivacy: true,
  withEmailAddress: true,
  tryAgain: false,
  spinerWrapClassName: "",
  classNameSubmit: ""
};

EmailForm.propTypes = {
  fieldClassName: PropTypes.string,
  successHeaderTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  errorHeaderTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  classNameForm: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  buttonTextKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  classNameSubmit: PropTypes.string,
  withPrivacy: PropTypes.bool,
  withEmailAddress: PropTypes.bool,
  successFooterText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  errorFooterTextNotConnectServer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  isNotFindEmailText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  content: PropTypes.object,
  apiLink: PropTypes.string.isRequired,
  spinerWrapClassName: PropTypes.string,
  tryAgain: PropTypes.bool,
};

export default EmailForm;
