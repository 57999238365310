import "./style.scss";

import { createClassName } from "../../../helpers";

import Container from "../../Container";
import Breadcrumbs from "../index";

const cn = createClassName("breadcrumbs");

const BreadcrumbsBlock = (props) => (
  <Container className={cn("", { wrap: true })}>
    <Breadcrumbs page={props.frontmatter.bannerTitle} path={props.frontmatter.path} />
  </Container>
  );

export default BreadcrumbsBlock;
