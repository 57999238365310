import "./style.scss";

import PropTypes from "prop-types";
import { Fragment } from "react";
import { createClassName, createLinkPoints } from "../../helpers";

import BreadcrumpsIcon from "../Icons/BreadcrumpsIcon";
import Link from "../Links/LinkOnPage";
import TranslationWord from "../Translate_component";

// import { useTranslation } from "react-i18next/dist/es/useTranslation";

const cn = createClassName("breadcrumbs");

const Breadcrumbs = (props) => {
  // const { t } = useTranslation("common");

  const pathPoints = createLinkPoints(props.path);

  let linkCounter = "";

  const newPath = pathPoints.map((item, index) => {
    if (item !== "release-notes" && index === 1) {
      linkCounter = `${item}/`;
    } else {
      linkCounter += `${item}/`;
    }

    return (
      <Fragment key={index}>
        <Link href={`/${linkCounter}`} className={cn("link", { parent: true })} color="gray">
          <TranslationWord text={item} />
          {/* {t(item)} */}
        </Link>
        <BreadcrumpsIcon className={cn("icon")} />
      </Fragment>
    );
  });

  return (
    <nav className={cn("wrap")}>
      {newPath}
      <div className={cn("link", { wrap: true })}>
        <span className={cn("link", { active: true })}>{props.page}</span>
      </div>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  path: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export default Breadcrumbs;
