import {
  heading,
  paragraph,
  renderCom,
} from "..";
import { createClassName } from "../../../helpers";

const cn = createClassName("RemainderAndDownload");

const previewTitle = ({
 children, id, sidebar, level = 1, fontLevel = 3, weight = "normal",
}) => (
  <div id={sidebar || id} className={cn("heading", { section: true })}>
    {heading({
 children, id, level, fontLevel, weight,
})}
  </div>
);

const renderComToolkit = {
  "preview-title": previewTitle,
  p: paragraph,
};

const supportCom = renderCom(renderComToolkit);

export default supportCom;
