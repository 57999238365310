import "./style.scss";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import Click from "../../Icons/Click";
import Link from "../../Links/LinkOnPage/_withArrow";
import Notice from "..";
import { SuccessFooterTextDefault } from "../constantMessage";
import Title from "../../Title";
import { createClassName } from "../../../helpers";

const cn = createClassName("successfullySubmit");

const SuccessfullySubmit = (props) => {
  const { t } = useTranslation("common");

  const successFooterTextDefault = (
    <SuccessFooterTextDefault
      className={cn("button", { "try-again": true })}
      color="default"
      handler={props.sendNewData}
    />
  );

  const nextStepBlock = (
    <div className={props.nextStepClassName}>
      <Title level={4} weight="medium">
        {t("next-step")}
      </Title>
      <div className={cn("nextStep", { wrap: true })}>
        <Click className={cn("nextStep", { icon: true })} />
        <div className={cn("nextStep", { content: true })}>
          <span className={cn("nextStep", { text: true })}>
            {props.nextStepText
              ? props.nextStepText
              : t("strings:nextStepText")}
          </span>
          <div className={cn("nextStep", { link: true })}>
            <Link href="/support/getting-started/" color="primary-main">
              {t("lets-go")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={props.className}>
      <Title level={4} weight="medium" {...props}>
        {props.successfullySubmitTitle
          ? props.successfullySubmitTitle
          : t("thanks")}
      </Title>
      <Notice
        mailerAddress={props.mailerAddress}
        successSend={props.successSend}
        successHeaderTitle={props.successHeaderTitle}
        successFooterText={
          props.successFooterText
            ? props.successFooterText
            : successFooterTextDefault
        }
        handler={props.handler}
        className={`${cn("content", { wrap: true })} ${cn(
          props.noticeClassName,
        )}`}
      />
      {props.nextStep && nextStepBlock}
    </div>
  );
};

SuccessfullySubmit.defaultProps = {
  successSend: true,
  nextStep: true,
  nextStepClassName: "",
};

SuccessfullySubmit.propTypes = {
  successSend: PropTypes.bool,
  className: PropTypes.string,
  noticeClassName: PropTypes.string,
  handler: PropTypes.func,
  nextStep: PropTypes.bool.isRequired,
  nextStepText: PropTypes.string,
  nextStepClassName: PropTypes.string,
  successFooterText: PropTypes.any,
};

export default SuccessfullySubmit;
